import { Injectable } from '@angular/core';
import { Firestore, collection, addDoc, doc, deleteDoc, updateDoc, getDoc, orderBy, query, getDocs, where } from '@angular/fire/firestore';
import { Subscriber } from '@shared/models/subscriber';

@Injectable()
export class SubscriberService {

    constructor(private firestore: Firestore) {
    }

    async add(company: Subscriber): Promise<string> {
        return await addDoc(collection(this.firestore, `subscribers`), Object.assign({}, company)).then(d => {
            return d.id;
        });
    }

    update(subscriberId: string, company: Subscriber): any {
        updateDoc(doc(this.firestore, `subscribers/${subscriberId}`), { ...company });
    }

    delete(subscriberId: string): any {
        deleteDoc(doc(this.firestore, `subscribers/${subscriberId}`));
    }

    async get(subscriberId: string): Promise<Subscriber> {
        const ref = doc(this.firestore, `subscribers/${subscriberId}`);
        const docSnap = await getDoc(ref);

        if (docSnap.exists()) {
            const data = docSnap.data();
            const subscriber = {
                ...data,
                id: docSnap.id,
            } as Subscriber;
            return subscriber;
        } else {
            return undefined;
        }
    }

    async getAll(): Promise<Subscriber[]> {
        const q = query(collection(this.firestore, `subscribers`), where('subscriber_type', "!=", 'free'), orderBy('name', 'asc'));

        const data = await getDocs(q);

        const replySet: Subscriber[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new Subscriber(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }
}