import { Component, OnInit } from "@angular/core";
import { Router } from "@angular/router";
import { AlertController, ToastController } from "@ionic/angular";
import {
  UntypedFormGroup,
  UntypedFormBuilder,
  Validators,
  UntypedFormControl,
} from "@angular/forms";
import { AuthService } from "../../services/auth.service";
import { TranslateService } from '@ngx-translate/core'; // Import TranslateService

@Component({
  selector: "app-password-reset",
  templateUrl: "./password-reset.page.html",
  styleUrls: ["./password-reset.page.scss"],
})
export class PasswordResetPage implements OnInit {

  public reset_form: UntypedFormGroup;
  public errorMessage: string;

  public validation_messages = {
    email: [
      { type: "required", message: 'PASSWORD_RESET_PAGE.EMAIL_REQUIRED_ERROR' },
      { type: "pattern", message: 'PASSWORD_RESET_PAGE.EMAIL_INVALID_ERROR' },
    ],
  };

  constructor(
    private authService: AuthService,
    private alertCtrl: AlertController,
    private router: Router,
    private toastController: ToastController,
    private formBuilder: UntypedFormBuilder,
    private translate: TranslateService // Inject TranslateService
  ) {
  }

  ngOnInit() {
    this.reset_form = this.formBuilder.group({
      email: new UntypedFormControl(
        "",
        Validators.compose([
          Validators.required,
          Validators.pattern("^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$"),
        ])
      ),
    });
  }

  async presentToast(text) {
    const toast = await this.toastController.create({
      message: text,
      duration: 2000,
    });
    toast.present();
  }

  dismiss() {
    this.router.navigateByUrl("login");
  }

  resetPassword(form: any): void {
    this.authService.resetPassword(form.email).then(
      async () => {
        const alert = await this.alertCtrl.create({
          message: this.translate.instant('PASSWORD_RESET_PAGE.RESET_EMAIL_SENT'),
          buttons: [
            {
              text: this.translate.instant('GENERAL.OK'),
              role: "cancel",
              handler: () => {
                this.router.navigateByUrl("login");
              },
            },
          ],
        });
        await alert.present();
      },
      async (error) => {
        const errorAlert = await this.alertCtrl.create({
          message: error.message,
          buttons: [{ text: this.translate.instant('GENERAL.OK'), role: "cancel" }],
        });
        await errorAlert.present();
      }
    );
  }
}