"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportTemplate = void 0;
var ReportTemplate = /** @class */ (function () {
    function ReportTemplate() {
        this.id = "";
        this.name = "";
        this.description = "";
        this.reporting_targets = [];
        this.target_type = ""; // Added target_type property
        this.created_at = new Date().toISOString();
        this.created_by = ""; // Set created_by when creating the report
        this.modified_at = new Date().toISOString();
        this.modified_by = ""; // Set modified_by when creating the report
    }
    return ReportTemplate;
}());
exports.ReportTemplate = ReportTemplate;
