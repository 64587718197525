import { Injectable } from '@angular/core';
import { openDB, DBSchema, IDBPDatabase } from 'idb';

interface LocalFileDBService extends DBSchema {
    files: {
        key: string; // UUID of the file
        value: { value: Blob; containerId: string }; // The file (image) as a Blob with containerId
        indexes: { containerId: string }; // Define the containerId index
    };
}

@Injectable({
    providedIn: 'root'
})
export class IndexedDbFileService {
    private dbPromise: Promise<IDBPDatabase<LocalFileDBService>>;

    constructor() {
        this.dbPromise = this.openDatabase();
    }

    private async openDatabase(): Promise<IDBPDatabase<LocalFileDBService>> {
        return openDB<LocalFileDBService>('proveit-storage', 2, { // Changed database name
            upgrade(db, oldVersion) {
                if (oldVersion < 2) {
                    const store = db.createObjectStore('files');
                    store.createIndex('containerId', 'containerId');
                }
            },
        });
    }

    async addFile(key: string, file: Blob, containerId: string): Promise<void> {
        const db = await this.dbPromise;
        await db.put('files', { value: file, containerId }, key);
    }

    async getFile(key: string): Promise<{ value: Blob; containerId: string } | undefined> {
        const db = await this.dbPromise;
        return db.get('files', key);
    }

    async deleteFile(key: string): Promise<void> {
        const db = await this.dbPromise;
        await db.delete('files', key);
    }

    async deleteFilesByContainerId(containerId: string): Promise<void> {

        console.log(`Deleting files from container with id ${containerId}`);
        const db = await this.dbPromise;
        const tx = db.transaction('files', 'readwrite');
        const index = tx.store.index('containerId');
        const keys = await index.getAllKeys(containerId);

        await Promise.all(keys.map(key => tx.store.delete(key)));
        await tx.done;
    }

    async getAllKeys(): Promise<string[]> {
        const db = await this.dbPromise;
        const keys = await db.getAllKeys('files'); // Get the keys directly
        return keys;
    }

    async clearAllFiles(): Promise<void> {
        const db = await this.dbPromise;
        await db.clear('files');
    }
}