import { Inject, Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from 'firebase/functions';
import { BatteryOptimization } from '@capawesome-team/capacitor-android-battery-optimization';
import { getApp } from 'firebase/app';
import { ENVIRONMENT } from '../tokens/app.tokens';
import { registerPlugin } from '@capacitor/core';
import { ForegroundService } from '@capawesome-team/capacitor-android-foreground-service';

const BackgroundGeolocation: any = registerPlugin('BackgroundGeolocation');

@Injectable({
    providedIn: 'root',
})
export class MobileTrackerService {
    private locationUpdateFunction;
    private watcherId: string | null = null;
    private notificationActive: boolean = false;

    constructor(private platform: Platform, @Inject(ENVIRONMENT) private environment: any) {
        const functions = getFunctions(getApp(), 'europe-west6');
        if (!environment.firebase_hosting) {
            connectFunctionsEmulator(functions, environment.firebase_emulator_host, 5001);
        }
        this.locationUpdateFunction = httpsCallable(functions, 'mobile_location_update');
    }

    async startTracking(): Promise<void> {
        await BatteryOptimization.requestIgnoreBatteryOptimization();
        const { enabled } = await BatteryOptimization.isBatteryOptimizationEnabled();
        console.log(`Battery optimization state: ${enabled}`);

        try {
            await BackgroundGeolocation.addWatcher(
                {
                    backgroundMessage: 'ProveIt Mobiilipaikannus käynnissä',
                    backgroundTitle: 'Paikannetaan',
                    requestPermissions: true,
                    stale: false,
                    distanceFilter: 0,
                },
                (location, error) => {
                    if (error) {
                        if (error.code === 'NOT_AUTHORIZED') {
                            if (window.confirm('This app needs your location, but does not have permission.\n\nOpen settings now?')) {
                                BackgroundGeolocation.openSettings();
                            }
                        }
                        console.error(error);
                        return;
                    }
                    const { latitude, longitude } = location;
                    console.log(`Background location: ${JSON.stringify(location)}`);
                    this.sendLocationToServer(latitude, longitude, (timestamp) => {
                        this.notifyLocationUpdated(timestamp);
                        this.updateNotification({ latitude, longitude, timestamp });
                    });
                    console.log(location);
                }
            ).then((watcherId) => {
                this.watcherId = watcherId;
                this.startNotificationService();
            });
        } catch (error) {
            console.error('Error requesting location permissions:', error);
        }
    }

    async startNotificationService() {
        try {
            if (this.platform.is('android')) {
                const channel = {
                    id: '3',
                    name: 'Location Updates',
                    description: 'Notifications for location tracking',
                    importance: 5, // NotificationImportance.HIGH
                    visibility: 1, // NotificationVisibility.PRIVATE
                };
                await ForegroundService.createNotificationChannel(channel);
            }

            await ForegroundService.startForegroundService({
                id: 101,
                title: 'Location Data',
                body: 'Waiting for location updates...',
                smallIcon: 'ic_notification',
                notificationChannelId: '3',
            });
            this.notificationActive = true;
        } catch (error) {
            console.error('Error starting notification service:', error);
        }
    }
    async updateNotification(data: { latitude: number; longitude: number; timestamp: number }) {
        if (this.notificationActive) {
            try {
                await ForegroundService.updateForegroundService({
                    id: 101,
                    title: 'Location Data',
                    body: `Lat: ${data.latitude.toFixed(6)}, Lon: ${data.longitude.toFixed(6)}, Time: ${new Date(data.timestamp).toLocaleTimeString()}`,
                    smallIcon: 'ic_notification',
                    notificationChannelId: '3',
                });
            } catch (error) {
                console.error('Error updating notification:', error);
            }
        }
    }

    private sendLocationToServer(latitude: number, longitude: number, callback: (timestamp: number) => void): void {
        console.log(`Sending location: Latitude=${latitude}, Longitude=${longitude}`);
        const requestData = {
            location: {
                lat: latitude,
                lon: longitude,
            },
        };

        this.locationUpdateFunction(requestData)
            .then((result: any) => {
                if (result.data.status_code === 1) {
                    console.log('Location sent successfully:', result.data.status_message);
                    callback(Date.now());
                    console.log('sendLocationToServer finished');
                } else {
                    console.error('Error sending location:', result.data.status_message);
                    console.log('sendLocationToServer finished');
                }
            })
            .catch((error) => {
                console.error('Error calling location update function:', error);
                console.log('sendLocationToServer finished');
            });
    }

    async stopTracking(): Promise<void> {
        if (this.watcherId) {
            try {
                await BackgroundGeolocation.removeWatcher({ id: this.watcherId });
                this.watcherId = null;
                this.notificationActive = false;
                console.log('Tracking stopped.');
            } catch (error) {
                console.error('Error stopping tracking:', error);
            }
        } else {
            console.warn('No active watcher to stop.');
        }
    }

    private notifyLocationUpdated(timestamp: number): void {
        const event = new CustomEvent('locationUpdated', { detail: { timestamp } });
        window.dispatchEvent(event);
    }

    public isTrackingActive(): boolean {
        return this.watcherId !== null;
    }
}