"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Event = void 0;
var Event = /** @class */ (function () {
    function Event() {
        this.subscriber_id = "";
        this.event_time = "";
        this.lat = 0;
        this.lon = 0;
        this.device_id = "";
        this.title = "";
        this.parameters = null;
        this.event_type = "";
        this.asset_id = "";
        this.user_id = "";
        this.event_value = "";
    }
    Event.prototype.setParameters = function (value) {
        if (value === null) {
            this.parameters = null;
        }
        else if (typeof value === "string" || typeof value === "number") {
            this.parameters = String(value); // Convert to string for BigQuery
        }
        else if (typeof value === "object") {
            this.parameters = JSON.stringify(value);
        }
        else {
            this.parameters = null; // Default to null for unsupported types
        }
    };
    Event.prototype.toJSON = function () {
        return {
            subscriber_id: this.subscriber_id,
            event_time: this.event_time,
            lat: this.lat,
            lon: this.lon,
            device_id: this.device_id,
            asset_id: this.asset_id,
            user_id: this.user_id,
            title: this.title,
            event_type: this.event_type,
            event_value: this.event_value,
            parameters: this.parameters
        };
    };
    return Event;
}());
exports.Event = Event;
