// language-initializer.service.ts (Revised)
import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from './auth.service';

@Injectable({
    providedIn: 'root',
})
export class LanguageInitializerService {
    constructor(private translate: TranslateService, private auth: AuthService) { }

    async initializeApp(): Promise<void> {
        return new Promise((resolve) => {
            const userProfile = this.auth.getUserProfileStatic();

            if (userProfile?.language) {
                const langParts = userProfile.language.split('-');
                this.translate.use(langParts[0]);
                console.log(`APP_INITIALIZER: Using user preferred language from localStorage: ${langParts[0]}`);
            } else {
                const browserLang = this.translate.getBrowserLang();
                this.translate.use(browserLang ? browserLang : 'en');
                console.log(`APP_INITIALIZER: User language not in localStorage, using browser language: ${browserLang ? browserLang : 'en'}`);
            }
            this.translate.setDefaultLang('fi');
            resolve();
        });
    }
}