import { Injectable } from '@angular/core';
import { Firestore, collection, addDoc, doc, deleteDoc, updateDoc, query, getDocs, orderBy, limit, startAfter, where } from '@angular/fire/firestore';
import { Report } from '@shared/models/report';

@Injectable()
export class ReportService {

    private historyCursor = null;

    constructor(private firestore: Firestore) { }

    async add(subscriberId: string, report: Report): Promise<string> {
        return await addDoc(collection(this.firestore, `subscribers/${subscriberId}/reports`), this.convertToPlainObject(report)).then(d => {
            return d.id;
        });
    }

    async update(subscriberId: string, reportId: string, report: Report): Promise<void> {
        await updateDoc(doc(this.firestore, `subscribers/${subscriberId}/reports/${reportId}`), this.convertToPlainObject(report));
    }

    async delete(subscriberId: string, reportId: string): Promise<void> {
        await deleteDoc(doc(this.firestore, `subscribers/${subscriberId}/reports/${reportId}`));
    }

    async getAll(subscriberId: string): Promise<Report[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/reports`), orderBy('name', 'asc'),);

        const data = await getDocs(q);

        const replySet: Report[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new Report(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    async getAllByUserPaged(subscriberId: string, userId: string, reset: boolean, queryLimit: number): Promise<Report[]> {
        const queryPath = `subscribers/${subscriberId}/reports`;

        let q = null;
        if (this.historyCursor !== null && this.historyCursor !== undefined && reset == false) {
            q = query(collection(this.firestore, queryPath), where('created_by', '==', userId), orderBy('date', 'desc'), limit(queryLimit), startAfter(this.historyCursor));
        }
        else {
            q = query(collection(this.firestore, queryPath), where('created_by', '==', userId), orderBy('date', 'desc'), limit(queryLimit));
        }

        const data = await getDocs(q);
        const replySet: Report[] = [];

        if (data.docs.length > 0) {
            this.historyCursor = data.docs[data.docs.length - 1];

            data.docs.forEach(doc => {
                const o = Object.assign(new Report(), doc.data());
                o.id = doc.id;
                replySet.push(o);
            });
        }

        return replySet;
    }

    private convertToPlainObject(report: Report): any {
        // Create a copy of the report object
        const plainReport = { ...report };

        // Convert ReportingTarget objects to plain objects
        if (plainReport.reporting_targets && plainReport.reporting_targets.length > 0) {
            plainReport.reporting_targets = plainReport.reporting_targets.map(target => ({ ...target }));
        }

        return plainReport;
    }
}