import { Injectable } from '@angular/core';
import { Firestore, collectionData, collection, addDoc, doc, deleteDoc, updateDoc, getDocs, limit, orderBy, query, startAfter, or, where, and, getDoc, setDoc } from '@angular/fire/firestore';
import { User } from '@shared/models/user';
import { Observable } from 'rxjs';

@Injectable()
export class UserService {

    private historyCursor = null;

    constructor(private firestore: Firestore) {
    }

    async add(subscriberId: string, user: User): Promise<string> {
        // The object is nested, so the assign does not work
        return await addDoc(collection(this.firestore, `subscribers/${subscriberId}/users`), JSON.parse(JSON.stringify(user))).then(d => {
            return d.id;
        });
    }

    async upsert(subscriberId: string, userId: string, user: User): Promise<void> {
        const userDocRef = doc(this.firestore, `subscribers/${subscriberId}/users/${userId}`);
        const docSnap = await getDoc(userDocRef);

        try {
            if (docSnap.exists()) {
                await updateDoc(userDocRef, JSON.parse(JSON.stringify(user)));
                console.log(`Document updated with ID: ${userId}`);
            } else {
                await setDoc(userDocRef, JSON.parse(JSON.stringify(user)));
                console.log(`Document created with ID: ${userId}`);
            }
        } catch (error) {
            console.error('Error during upsert:', error);
            throw error;
        }
    }

    update(subscriberId: string, userId: string, user: User): any {
        // The object is nested, so the assign does not work
        updateDoc(doc(this.firestore, `subscribers/${subscriberId}/users/${userId}`), JSON.parse(JSON.stringify(user)));
    }

    delete(subscriberId: string, userId: string): any {
        deleteDoc(doc(this.firestore, `subscribers/${subscriberId}/users/${userId}`));
    }

    subscribeAll(subscriberId: string): any {
        return collectionData(collection(this.firestore, `subscribers/${subscriberId}/users`), { idField: 'id' }) as Observable<User[]>;
    }

    async filter(subscriberId: string, filterBy: string): Promise<User[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/users`),
            or(
                and(where('lastname', '>=', filterBy), where('lastname', '<=', filterBy + '\uf8ff')),
                and(where('firstname', '>=', filterBy), where('firstname', '<=', filterBy + '\uf8ff'))
            ));

        const data = await getDocs(q);
        const replySet: User[] = [];

        if (data.docs.length > 0) {
            this.historyCursor = data.docs[data.docs.length - 1];

            data.docs.forEach(doc => {
                const o = Object.assign(new User(), doc.data());
                o.id = doc.id;
                replySet.push(o);
            });
        }

        return replySet;
    }

    async get(subscriberId: string, userId: string): Promise<User> {
        const ref = doc(this.firestore, `subscribers/${subscriberId}/users/${userId}`);
        return Object.assign(new User(), (await getDoc(ref)).data());
    }

    async getAll(subscriberId: string): Promise<User[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/users`), orderBy('lastname', 'asc'));

        const data = await getDocs(q);
        const replySet: User[] = [];

        if (data.docs.length > 0) {
            this.historyCursor = data.docs[data.docs.length - 1];

            data.docs.forEach(doc => {
                const o = Object.assign(new User(), doc.data());
                o.id = doc.id;
                replySet.push(o);
            });
        }

        return replySet;
    }
}