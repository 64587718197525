import { Injectable } from '@angular/core';
import { Firestore, collection, addDoc, doc, deleteDoc, updateDoc, query, getDocs, orderBy } from '@angular/fire/firestore';
import { ReportingTarget } from '@shared/models';
import { ReportTemplate } from '@shared/models/report-template'; // Adjust import path

@Injectable()
export class ReportTemplateService {

    constructor(private firestore: Firestore) {
    }

    async add(subscriberId: string, report: ReportTemplate): Promise<string> {
        const reportPlainObject = this.toPlainObject(report);
        return await addDoc(collection(this.firestore, `subscribers/${subscriberId}/report-templates`), reportPlainObject).then(d => {
            return d.id;
        });
    }

    async update(subscriberId: string, reportId: string, report: ReportTemplate): Promise<void> {
        const reportPlainObject = this.toPlainObject(report);
        await updateDoc(doc(this.firestore, `subscribers/${subscriberId}/report-templates/${reportId}`), reportPlainObject);
    }

    async delete(subscriberId: string, reportId: string): Promise<void> {
        await deleteDoc(doc(this.firestore, `subscribers/${subscriberId}/report-templates/${reportId}`));
    }

    async getAll(subscriberId: string): Promise<ReportTemplate[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/report-templates`), orderBy('name', 'asc'));

        const data = await getDocs(q);

        const replySet: ReportTemplate[] = [];

        data.docs.forEach(doc => {
            const plainObject = doc.data();
            const report = this.fromPlainObject(plainObject);
            report.id = doc.id;
            replySet.push(report);
        });

        return replySet;
    }

    private toPlainObject(report: ReportTemplate): any {
        const plainReport: any = { ...report };
        if (report.reporting_targets) {
            plainReport.reporting_targets = report.reporting_targets.map(target => ({ ...target }));
        }
        return plainReport;
    }

    private fromPlainObject(plainObject: any): ReportTemplate {
        const report = new ReportTemplate();
        Object.assign(report, plainObject);

        if (plainObject.reporting_targets) {
            report.reporting_targets = plainObject.reporting_targets.map((target: any) => {
                const reportingTarget = new ReportingTarget();
                Object.assign(reportingTarget, target);
                return reportingTarget;
            });
        }
        return report;
    }

    async seedBuildingInspectionTemplates(subscriberId: string): Promise<void> {
        const templates: ReportTemplate[] = [
            this.createTemplate(
                'Perustusten tarkastus',
                'Perustusten rakennusvaiheen tarkastus.',
                this.createReportingTargets([
                    { name: 'Perustusten muottien tarkastus', description: 'Muottien oikea asennus ja mittatarkkuus.', require_photo: true, checkListItems: ['Muottien suoruus', 'Mittatarkkuus', 'Tuenta'] },
                    { name: 'Rautojen asennus', description: 'Rautojen oikea sijainti ja määrä.', require_photo: true, checkListItems: ['Rautojen sijainti', 'Rautojen määrä', 'Sidontalangat'] },
                    { name: 'Betonivalu', description: 'Betonivalun laatu ja suoritus.', require_photo: true, require_text: true, checkListItems: ['Betonin laatu', 'Valun suoruus', 'Tiivistys'] },
                    { name: 'Perustusten vedeneristys', description: 'Vedeneristyksen oikea asennus.', require_photo: true, checkListItems: ['Vedeneristyksen asennus', 'Saumojen tiiveys'] },
                    { name: 'Salaojitus', description: 'Salaojituksen toimivuus.', require_photo: true, checkListItems: ['Salaojaputkien asennus', 'Kaivon toimivuus'] },
                    { name: 'Täyttötyöt', description: 'Täyttötöiden laatu.', require_photo: true, checkListItems: ['Täyttömaan laatu', 'Tiivistys'] },
                    { name: 'Perustusten mittatarkkuus', description: 'Perustusten mittojen tarkistus.', require_text: true, checkListItems: ['Mittatarkkuus', 'Suoruus'] },
                    { name: 'Perustusten lämpöeristys', description: 'Lämpöeristyksen oikea asennus.', require_photo: true, checkListItems: ['Erityksen asennus', 'Saumojen tiiveys'] },
                    { name: 'Perustusten tuuletus', description: 'Tuuletuksen toimivuus.', require_photo: true, checkListItems: ['Tuuletusputkien asennus', 'Ilmanvaihto'] },
                    { name: 'Perustusten tarkastusluukut', description: 'Tarkastusluukkujen asennus.', require_photo: true, checkListItems: ['Luukkujen asennus', 'Tiiveys'] },
                    { name: 'Perustusten sisäpuolinen tarkastus', description: 'Sisäpuolisten rakenteiden tarkastus.', require_photo: true, checkListItems: ['Rakenteiden kunto', 'Mahdolliset vauriot'] },
                    { name: 'Perustusten ulkopuolinen tarkastus', description: 'Ulkopuolisten rakenteiden tarkastus.', require_photo: true, checkListItems: ['Rakenteiden kunto', 'Mahdolliset vauriot'] },
                    { name: 'Perustusten dokumentointi', description: 'Tarkastusten dokumentointi.', require_text: true, checkListItems: ['Kuvat', 'Muistiinpanot'] },
                    { name: 'Perustusten virheiden korjaus', description: 'Mahdollisten virheiden korjaus.', require_text: true, checkListItems: ['Korjausten kuvaus', 'Korjausten dokumentointi'] },
                    { name: 'Perustusten lopputarkastus', description: 'Perustusten lopullinen tarkastus.', require_photo: true, require_text: true, checkListItems: ['Kokonaisarvio', 'Lopputuloksen dokumentointi'] },
                ])
            ),
            this.createTemplate(
                'Runkoasennuksen tarkastus',
                'Runkorakenteiden asennusvaiheen tarkastus.',
                this.createReportingTargets([
                    { name: 'Runkoasennuksen suoruus', description: 'Runkorakenteiden suoruuden tarkastus.', require_photo: true, checkListItems: ['Suoruus', 'Mittatarkkuus'] },
                    { name: 'Runkomateriaalien laatu', description: 'Runkomateriaalien laadun tarkastus.', require_photo: true, checkListItems: ['Materiaalin kunto', 'Vauriot'] },
                    { name: 'Runkoliitosten tarkastus', description: 'Liitosten oikea toteutus.', require_photo: true, checkListItems: ['Liitosten tiiveys', 'Kiinnitykset'] },
                    { name: 'Väliseinien asennus', description: 'Väliseinien oikea asennus.', require_photo: true, checkListItems: ['Suoruus', 'Kiinnitys'] },
                    { name: 'Kattoristikoiden asennus', description: 'Kattoristikoiden oikea asennus.', require_photo: true, checkListItems: ['Suoruus', 'Kiinnitys'] },
                    { name: 'Vesikaton asennus', description: 'Vesikaton oikea asennus.', require_photo: true, checkListItems: ['Tiiveys', 'Kiinnitys'] },
                    { name: 'Ikkunoiden ja ovien asennus', description: 'Ikkunoiden ja ovien oikea asennus.', require_photo: true, checkListItems: ['Tiiveys', 'Kiinnitys'] },
                    { name: 'Runkorakenteiden tuuletus', description: 'Tuuletuksen toimivuus.', require_photo: true, checkListItems: ['Tuuletusrakojen toimivuus', 'Ilmanvaihto'] },
                    { name: 'Runkorakenteiden lämpöeristys', description: 'Lämpöeristyksen oikea asennus.', require_photo: true, checkListItems: ['Erityksen asennus', 'Saumojen tiiveys'] },
                    { name: 'Runkorakenteiden äänieristys', description: 'Äänieristyksen oikea asennus.', require_photo: true, checkListItems: ['Erityksen asennus', 'Saumojen tiiveys'] },
                    { name: 'Runkorakenteiden paloturvallisuus', description: 'Paloturvallisuuden tarkastus.', require_photo: true, checkListItems: ['Palokatkot', 'Palosuojalevyt'] },
                    { name: 'Runkorakenteiden mittatarkkuus', description: 'Mittatarkkuuden tarkastus.', require_text: true, checkListItems: ['Mittatarkkuus', 'Suoruus'] },
                    { name: 'Runkorakenteiden dokumentointi', description: 'Tarkastusten dokumentointi.', require_text: true, checkListItems: ['Kuvat', 'Muistiinpanot'] },
                    { name: 'Runkorakenteiden virheiden korjaus', description: 'Mahdollisten virheiden korjaus.', require_text: true, checkListItems: ['Korjausten kuvaus', 'Korjausten dokumentointi'] },
                    { name: 'Runkoasennuksen lopputarkastus', description: 'Runkoasennuksen lopullinen tarkastus.', require_photo: true, require_text: true, checkListItems: ['Kokonaisarvio', 'Lopputuloksen dokumentointi'] },
                ])
            ),
            // Add 8 more templates here following the same pattern.
            // ... (Remaining 8 templates)
        ];

        for (const template of templates) {
            console.log(`Adding template ${JSON.stringify(template)}`);
            await this.add(subscriberId, template);
        }
    }

    private createTemplate(name: string, description: string, reportingTargets: ReportingTarget[]): ReportTemplate {
        const template = new ReportTemplate();
        template.name = name;
        template.description = description;
        template.reporting_targets = reportingTargets;
        return template;
    }

    private createReportingTargets(targets: any[]): ReportingTarget[] {
        return targets.map((target, index) => {
            const reportingTarget = new ReportingTarget();
            reportingTarget.name = target.name;
            reportingTarget.description = target.description;
            reportingTarget.require_photo = target.require_photo || false;
            reportingTarget.require_text = target.require_text || false;
            reportingTarget.require_photo_with_text = target.require_photo_with_text || false;
            reportingTarget.max_photos = target.max_photos || 0;
            reportingTarget.max_text = target.max_text || 0;
            reportingTarget.max_photo_with_text = target.max_photo_with_text || 0;
            reportingTarget.check_list_items = target.checkListItems || [];
            reportingTarget.marked_as_done = false;
            reportingTarget.order = index + 1; // Assign order based on array index
            return reportingTarget;
        });
    }
}