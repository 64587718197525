// src/app/app.config.ts (or language-factories.ts)

import { registerLocaleData } from '@angular/common';
import localeFi from '@angular/common/locales/fi';
import localeEn from '@angular/common/locales/en';
import localeEs from '@angular/common/locales/es';
import localeIt from '@angular/common/locales/it';
import localeSv from '@angular/common/locales/sv';
import localeNo from '@angular/common/locales/no';
import localeFr from '@angular/common/locales/fr';
import localeDe from '@angular/common/locales/de';
import localePt from '@angular/common/locales/pt';

import { AuthService } from '../services/auth.service';
import { LanguageInitializerService } from '../services/language-initializer.service';

// Register the locale data for the supported languages
registerLocaleData(localeFi, 'fi-FI');
registerLocaleData(localeEn, 'en-US');
registerLocaleData(localeEs, 'es-ES');
registerLocaleData(localeIt, 'it-IT');
registerLocaleData(localeSv, 'sv-SE');
registerLocaleData(localeNo, 'no-NO');
registerLocaleData(localeFr, 'fr-FR');
registerLocaleData(localeDe, 'de-DE');
registerLocaleData(localePt, 'pt-PT');

export const supportedLanguages = [
    { code: 'fi-FI', label: 'Finnish' },
    { code: 'en-US', label: 'English' },
    { code: 'es-ES', label: 'Spanish' },
    { code: 'it-IT', label: 'Italian' },
    { code: 'sv-SE', label: 'Swedish' },
    { code: 'no-NO', label: 'Norwegian' },
    { code: 'fr-FR', label: 'French' },
    { code: 'de-DE', label: 'German' },
    { code: 'pt-PT', label: 'Portuguese' },
].sort((a, b) => a.label.localeCompare(b.label)); // Sort alphabetically by label

export function appInitializerFactory(languageInitializer: LanguageInitializerService) {
    return () => languageInitializer.initializeApp();
}

export function localeIdFactory(auth: AuthService): string {
    const userProfile = auth.getUserProfileStatic();
    if (userProfile?.language) {
        return userProfile.language;
    }
    const browserLang = navigator.language.split('-')[0];
    switch (browserLang) {
        case 'fi': return 'fi-FI';
        case 'en': return 'en-US';
        case 'es': return 'es-ES';
        case 'it': return 'it-IT';
        case 'sv': return 'sv-SE';
        case 'no': return 'no-NO';
        case 'fr': return 'fr-FR';
        case 'de': return 'de-DE';
        case 'pt': return 'pt-PT';
        default: return 'en-US'; // Default fallback
    }
}

export function getSupportedLanguages(): { code: string; label: string }[] {
    return supportedLanguages;
}