"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ReportingTarget = void 0;
var ReportingTarget = /** @class */ (function () {
    function ReportingTarget() {
        this.id = "";
        this.order = 0;
        this.name = "";
        this.description = "";
        this.require_photo = false;
        this.require_text = false;
        this.require_photo_with_text = false;
        this.max_photos = 0;
        this.max_text = 0;
        this.min_photos = 0;
        this.min_text = 0;
        this.max_photo_with_text = 0;
        this.check_list_items = [];
        this.marked_as_done = false;
        this.allow_delete = false;
        this.allow_copy = false;
    }
    return ReportingTarget;
}());
exports.ReportingTarget = ReportingTarget;
