import { Component, Inject, OnInit } from '@angular/core';
import { getApp } from '@angular/fire/app';
import { getFunctions, httpsCallable, connectFunctionsEmulator } from '@angular/fire/functions';
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { LoadingController, ToastController } from '@ionic/angular';
import { ENVIRONMENT } from "../../tokens/app.tokens";
import { availableLanguages, serviceAvailableIncountries } from '../../data/service-available-countries';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-register',
  templateUrl: './register.page.html',
  styleUrls: ['./register.page.css'],
})
export class RegisterPage implements OnInit {

  public firstname: string;
  public lastname: string;
  public email: string;
  public phone: string;

  public loading: any = false;

  public form: any;

  public countries: any[] = [];
  public languages: any[] = [];

  constructor(
    private readonly router: Router,
    @Inject(ENVIRONMENT) public environment: any,
    private loadingController: LoadingController,
    private fb: UntypedFormBuilder,
    private translate: TranslateService,
    private toastController: ToastController,) {

    this.countries = serviceAvailableIncountries;
    this.languages = availableLanguages;
  }

  ngOnInit() {

    this.form = this.fb.group({
      firstname: ['', [Validators.required]],
      lastname: ['', Validators.required],
      email: ['', [Validators.required, Validators.email]],
      phone: ['', Validators.required],
      country: ['', Validators.required],
      language: ['', Validators.required]
    });
  }

  back() {
    this.router.navigate(['/login']);
  }

  async save() {
    const data = this.form.value;
    data.private_user = true;

    // Get the selected country and add timezone information
    const selectedCountry = data.country;
    const countryInfo = this.countries.find(country => country.id === selectedCountry);
    if (countryInfo) {
      data.timezone = countryInfo.timezone;
    }

    this.loading = await this.loadingController.create({
      message: this.translate.instant(`REGISTER.CREATING_ACCOUNT`),
    });
    this.loading.present();

    const functions = getFunctions(getApp(), 'europe-west6');
    const register = httpsCallable(functions, 'register');
    if (this.environment.firebase_hosting === false) {
      connectFunctionsEmulator(functions, "localhost", 5001);
    }

    return register(data)
      .then((result: any) => {
        this.loading.dismiss();
        if (result.data.statusCode === 1) {
          this.showToast(result.data.message);
          this.router.navigate(['/login']);
        }
        else if (result.data.statusCode === 0) {
          this.showToast(result.data.message);
        }
      })
      .catch((error) => {
        this.loading.dismiss();
        this.showToast(this.translate.instant(`REGISTER.ERROR_IN_REGISTRATION`));
      });
  }


  async showToast(message: string) {
    const toast = await this.toastController.create({
      message: message,
      duration: 1500,
      position: 'bottom'
    });
    await toast.present();
  }
}
