import { Injectable } from '@angular/core';
import { getStorage, ref, uploadBytesResumable } from '@angular/fire/storage';
import { FileUpload } from '@shared/models/file-upload';
import { v4 as uuid } from 'uuid';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  private storage: any;

  constructor() {
    this.storage = getStorage();
  }

  pushFileToStorage(subscriberId: string, fileUpload: FileUpload, path: string = 'receipts', reportId?: string): any {
    const fileName = reportId ? `${reportId}_${fileUpload.file.name}` : `${uuid()}_${fileUpload.file.name}`;
    const filePath = `${subscriberId}/${path}/${fileName}`;
    const storageRef = ref(this.storage, filePath);

    try {
      const uploadTask = uploadBytesResumable(storageRef, fileUpload.file);

      return uploadTask;
    } catch (ex) {
      console.log(ex);
    }
  }
}