"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.WorkDay = void 0;
var WorkDay = /** @class */ (function () {
    function WorkDay() {
        this.id = "";
        this.type = "";
        this.user_id = "";
        this.start = "";
        this.end = "";
        this.description = "";
        this.approved = false;
        this.completed = false;
        this.productive = 0;
        this.non_worktime = 0;
        this.main_task_duration = 0;
        this.main_task_accuracy = 0;
        this.approved_by = "";
        this.approved_at = "";
        this.state = "";
        this.approver_comments = "";
        this.daily_allowance_type = "";
        this.daily_allowance_country = "";
        this.daily_allowance_amount = 0;
        this.workday_duration = 0;
        this.vehicle_calculated = false;
        this.work_segments = [];
        this.data_distance = 0;
        this.data_duration = 0;
        this.harsh_events = [];
        this.eco_speed_seconds = 0;
        this.idling_seconds = 0;
        this.coasting_seconds = 0;
        this.cruise_seconds = 0;
        this.optimal_rpm_seconds = 0;
        this.averarge_fuel_consumption = 0;
        this.absolute_fuel_consumption = 0;
        this.proveit_score = 0;
        this.modified_at = "";
        this.modified_by = "";
        this.download_url = "";
    }
    return WorkDay;
}());
exports.WorkDay = WorkDay;
