"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.DrivingBehaviorService = void 0;
var DrivingBehaviorService = /** @class */ (function () {
    function DrivingBehaviorService() {
        this.gradeColors = {
            'A': '#009f00',
            'B': '#70cb00',
            'C': '#ffed00',
            'D': '#ffae00',
            'E': '#ff7600',
            'F': '#ff0000',
            'G': '#990000',
            'NaN': '#000000'
        };
        this.metricConfigs = {
            'harsh_acceleration': { label: 'Kiihdytyksiä', order: 1, showAlways: true },
            'harsh_braking': { label: 'Jarrutuksia', order: 2, showAlways: true },
            'idling_seconds': { label: 'Tyhjäkäyntiä', order: 4, showAlways: false },
            'harsh_cornering': { label: 'Kaarreajoa', order: 3, showAlways: true },
            'eco_speed_seconds': { label: 'Optiminopeutta', order: 5, showAlways: false },
            'coasting_seconds': { label: 'Rullausta', order: 6, showAlways: false },
            'cruise_seconds': { label: 'VNS', order: 7, showAlways: false },
            'optimal_rpm_seconds': { label: 'Kierrosluku', order: 8, showAlways: false },
        };
    }
    DrivingBehaviorService.prototype.classifyMetrics = function (metrics, customMetricWeights) {
        if (customMetricWeights === void 0) { customMetricWeights = null; }
        var results = {};
        // Ensure all values are 0 if no data is provided
        var distance = (metrics === null || metrics === void 0 ? void 0 : metrics.data_distance) || 0;
        var duration = (metrics === null || metrics === void 0 ? void 0 : metrics.data_duration) || 0;
        var ecoSpeed = (metrics === null || metrics === void 0 ? void 0 : metrics.eco_speed_seconds) || 0;
        var coasting = (metrics === null || metrics === void 0 ? void 0 : metrics.coasting_seconds) || 0;
        var cruise = (metrics === null || metrics === void 0 ? void 0 : metrics.cruise_seconds) || 0;
        var optimalRpm = (metrics === null || metrics === void 0 ? void 0 : metrics.optimal_rpm_seconds) || 0;
        var idling = (metrics === null || metrics === void 0 ? void 0 : metrics.idling_seconds) || 0;
        var harshAcceleration = (metrics === null || metrics === void 0 ? void 0 : metrics.harsh_acceleration) || 0;
        var harshBraking = (metrics === null || metrics === void 0 ? void 0 : metrics.harsh_braking) || 0;
        var harshCornering = (metrics === null || metrics === void 0 ? void 0 : metrics.harsh_cornering) || 0;
        var percentageMetrics = {
            'eco_speed_seconds': this.calculatePercentage(ecoSpeed, duration),
            'coasting_seconds': this.calculatePercentage(coasting, duration),
            'cruise_seconds': this.calculatePercentage(cruise, duration),
            'optimal_rpm_seconds': this.calculatePercentage(optimalRpm, duration), // Corrected calculation
        };
        for (var metricName in percentageMetrics) {
            var percentage = percentageMetrics[metricName];
            var grade = this.getGradeFromPercentage(percentage, metricName === 'coasting_seconds'); // Pass isCoasting
            results[metricName] = {
                value: percentage,
                grade: grade,
                color: this.gradeColors[grade],
                order: this.metricConfigs[metricName].order,
                showAlways: this.metricConfigs[metricName].showAlways
            };
        }
        // --- Idling (0 = A, 10 = G) ---
        var idlingPercentage = this.calculatePercentage(idling, duration);
        var idlingGrade = this.getGradeFromIdlingPercentage(idlingPercentage);
        results['idling_seconds'] = {
            value: idlingPercentage,
            grade: idlingGrade,
            color: this.gradeColors[idlingGrade],
            order: this.metricConfigs['idling_seconds'].order,
            showAlways: this.metricConfigs['idling_seconds'].showAlways
        };
        var countMetrics = {
            'harsh_acceleration': harshAcceleration,
            'harsh_braking': harshBraking,
            'harsh_cornering': harshCornering,
        };
        for (var metricName in countMetrics) {
            var count = countMetrics[metricName];
            var scaledCount = count * (distance / 1000); // Scale for grade calculation
            var grade = this.getGradeFromCount(Math.round(scaledCount)); // Round scaled count for grade
            results[metricName] = {
                value: count, // Corrected template literal
                grade: grade,
                color: this.gradeColors[grade],
                order: this.metricConfigs[metricName].order,
                showAlways: this.metricConfigs[metricName].showAlways
            };
        }
        // --- Calculate Driving Behavior Score ---
        var score = Math.round(this.calculateDrivingBehaviorScore(results, customMetricWeights));
        var proveitScoreResult = this.getProveitScoreResult(score);
        return { metrics: results, proveit_score: proveitScoreResult };
    };
    DrivingBehaviorService.prototype.calculatePercentage = function (value, total) {
        return total > 0 ? Math.round((value / total) * 100) : 0;
    };
    DrivingBehaviorService.prototype.getGradeFromPercentage = function (percentage, isCoasting) {
        if (isCoasting === void 0) { isCoasting = false; }
        if (isCoasting) {
            if (percentage >= 2) {
                return 'A';
            }
            else if (percentage >= 1.8) {
                return 'B';
            }
            else if (percentage >= 1.5) {
                return 'C';
            }
            else if (percentage >= 1.3) {
                return 'D';
            }
            else if (percentage >= 1) {
                return 'E';
            }
            else {
                return 'F'; // Anything below 1% is F.
            }
        }
        else {
            var grade = 'D';
            if (percentage >= 95) {
                grade = 'A';
            }
            else if (percentage >= 85) {
                grade = 'B';
            }
            else if (percentage >= 75) {
                grade = 'C';
            }
            else if (percentage <= 65 && percentage > 55) {
                grade = 'E';
            }
            else if (percentage <= 55 && percentage > 45) {
                grade = 'F';
            }
            else if (percentage <= 45) {
                grade = 'G';
            }
            return grade;
        }
    };
    DrivingBehaviorService.prototype.getGradeFromIdlingPercentage = function (percentage) {
        var idlingGrade = 'A';
        if (percentage >= 10) {
            idlingGrade = 'G';
        }
        else if (percentage >= 8) {
            idlingGrade = 'F';
        }
        else if (percentage >= 6) {
            idlingGrade = 'E';
        }
        else if (percentage >= 4) {
            idlingGrade = 'D';
        }
        else if (percentage >= 2) {
            idlingGrade = 'C';
        }
        else if (percentage > 1) {
            idlingGrade = 'B';
        }
        return idlingGrade;
    };
    DrivingBehaviorService.prototype.getGradeFromCount = function (count) {
        var grade = 'A';
        if (count >= 10) {
            grade = 'G';
        }
        else if (count >= 8) {
            grade = 'F';
        }
        else if (count >= 6) {
            grade = 'E';
        }
        else if (count >= 4) {
            grade = 'D';
        }
        else if (count >= 2) {
            grade = 'C';
        }
        else if (count > 0) {
            grade = 'B';
        }
        return grade;
    };
    DrivingBehaviorService.prototype.calculateDrivingBehaviorScore = function (metrics, customMetricWeights) {
        if (customMetricWeights === void 0) { customMetricWeights = null; }
        var accelerationGrade = metrics.harsh_acceleration.grade;
        var brakingGrade = metrics.harsh_braking.grade;
        var corneringGrade = metrics.harsh_cornering.grade;
        var idlingGrade = metrics.idling_seconds.grade;
        var ecoSpeedGrade = metrics.eco_speed_seconds.grade;
        var coastingGrade = metrics.coasting_seconds.grade;
        var cruiseGrade = metrics.cruise_seconds.grade;
        var optimalRpmGrade = metrics.optimal_rpm_seconds.grade;
        // Scale letter grades
        var weights = customMetricWeights || metricWeights; // Use custom or default weights
        var scaledAccelerationGrade = this.scaleGradeValue(this.getGradeValue(accelerationGrade)) * weights.harsh_acceleration.weight;
        var scaledBrakingGrade = this.scaleGradeValue(this.getGradeValue(brakingGrade)) * weights.harsh_braking.weight;
        var scaledCorneringGrade = this.scaleGradeValue(this.getGradeValue(corneringGrade)) * weights.harsh_cornering.weight;
        var scaledIdlingGrade = this.scaleGradeValue(this.getGradeValue(idlingGrade)) * weights.idling_seconds.weight;
        var scaledEcoSpeedGrade = this.scaleGradeValue(this.getGradeValue(ecoSpeedGrade)) * weights.eco_speed_seconds.weight;
        var scaledCoastingGrade = this.scaleGradeValue(this.getGradeValue(coastingGrade)) * weights.coasting_seconds.weight;
        var scaledCruiseGrade = this.scaleGradeValue(this.getGradeValue(cruiseGrade)) * weights.cruise_seconds.weight;
        var scaledOptimalRpmGrade = this.scaleGradeValue(this.getGradeValue(optimalRpmGrade)) * weights.optimal_rpm_seconds.weight;
        // Calculate average of non-zero scaled scores
        var sum = 0;
        var count = 0;
        if (scaledAccelerationGrade > 0) {
            sum += scaledAccelerationGrade;
            count++;
        }
        if (scaledBrakingGrade > 0) {
            sum += scaledBrakingGrade;
            count++;
        }
        if (scaledCorneringGrade > 0) {
            sum += scaledCorneringGrade;
            count++;
        }
        if (scaledIdlingGrade > 0) {
            sum += scaledIdlingGrade;
            count++;
        }
        if (scaledEcoSpeedGrade > 0) {
            sum += scaledEcoSpeedGrade;
            count++;
        }
        if (scaledCoastingGrade > 0) {
            sum += scaledCoastingGrade;
            count++;
        }
        if (scaledCruiseGrade > 0) {
            sum += scaledCruiseGrade;
            count++;
        }
        if (scaledOptimalRpmGrade > 0) {
            sum += scaledOptimalRpmGrade;
            count++;
        }
        if (count === 0) {
            return 0;
        }
        var averageScore = (sum / count) * 10;
        return Math.round(averageScore);
    };
    DrivingBehaviorService.prototype.scaleGradeValue = function (gradeValue) {
        if (gradeValue < 4) {
            return 0;
        }
        var scaledValue = 10 * Math.sqrt(gradeValue - 4) / Math.sqrt(6);
        return Math.max(0, Math.min(10, scaledValue));
    };
    DrivingBehaviorService.prototype.getGradeValue = function (grade) {
        switch (grade) {
            case 'A': return 10;
            case 'B': return 9;
            case 'C': return 8;
            case 'D': return 7;
            case 'E': return 6;
            case 'F': return 5;
            case 'G': return 4;
            default: return 0;
        }
    };
    DrivingBehaviorService.prototype.getProveitScoreResult = function (score) {
        var grade = 'D'; // Default grade
        if (score >= 90) {
            grade = 'A';
        }
        else if (score >= 80) {
            grade = 'B';
        }
        else if (score >= 70) {
            grade = 'C';
        }
        else if (score >= 60) {
            grade = 'D';
        }
        else if (score >= 50) {
            grade = 'E';
        }
        else if (score >= 30) {
            grade = 'F';
        }
        else {
            grade = 'G';
        }
        return {
            value: Math.round(score),
            grade: grade,
            color: this.gradeColors[grade],
            order: null,
            showAlways: null
        };
    };
    return DrivingBehaviorService;
}());
exports.DrivingBehaviorService = DrivingBehaviorService;
var metricWeights = {
    "eco_speed_seconds": { "category": 1, "weight": 1.2 },
    "harsh_acceleration": { "category": 1, "weight": 0.9 },
    "idling_seconds": { "category": 1, "weight": 1.3 },
    "coasting_seconds": { "category": 1, "weight": 0.9 },
    "harsh_cornering": { "category": 2, "weight": 0.8 },
    "harsh_braking": { "category": 2, "weight": 0.9 },
    "optimal_rpm_seconds": { "category": 3, "weight": 1.2 },
    "cruise_seconds": { "category": 3, "weight": 0.8 }
};
