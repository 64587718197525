<ion-content>
  <ion-grid class="login-grid" style="max-width: 500px;">
    <ion-row justify-content-center>
      <ion-col>
        <ion-img src="/assets/logo.png" class="logo"></ion-img>
        <h2 style="margin-bottom: 30px;">{{appName}}</h2>

        <form class="form" [formGroup]="form" (ngSubmit)="onSubmit()">
          <ion-item lines="full">
            <ion-input formControlName="email" name="email" type="text" required
              label="{{ 'LOGIN.EMAIL_LABEL' | translate }}"></ion-input>
          </ion-item>

          <ion-item lines="full">
            <ion-input formControlName="password" name="password" type="password" required
              label="{{ 'LOGIN.PASSWORD_LABEL' | translate }}"></ion-input>
          </ion-item>

          <ion-button style="margin-top: 20px" class="submit-btn" type="submit" expand="full" fill="outline">
            {{ 'LOGIN.LOGIN_BUTTON' | translate }}
          </ion-button>

          <ion-button style="margin-top: 20px;" color="medium" expand="full" fill="outline" (click)="requestPassword()">
            {{ 'LOGIN.FORGOT_PASSWORD_BUTTON' | translate }}
          </ion-button>

          <ion-button *ngIf="allowRegistration" style="margin-top: 20px;" color="medium" expand="full" fill="outline"
            (click)="createAccount()">
            {{ 'LOGIN.CREATE_ACCOUNT_BUTTON' | translate }}
          </ion-button>
        </form>

      </ion-col>
    </ion-row>
    <ion-row><a href="https://www.proveit.fi/terms-and-conditions">{{ 'LOGIN.TERMS_AND_PRIVACY' | translate
        }}</a></ion-row>
  </ion-grid>

  <ion-label class="copyright"> &copy; {{year}} Procode Oy<br />
  </ion-label>
</ion-content>