<ion-header [translucent]="true">
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-icon (click)="back()" name="arrow-back" slot="start"></ion-icon>
    </ion-buttons>
    <ion-title>{{ 'REGISTER.TITLE' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div class="card-item-content">
    <p>{{ 'REGISTER.SUBTITLE' | translate }}</p>

    <form [formGroup]="form">
      <ion-item>
        <ion-input label-placement="stacked" formControlName="firstname"
          label="{{ 'REGISTER.FIRSTNAME_LABEL' | translate }}" class="ion-text-left"></ion-input>
      </ion-item>

      <ion-item>
        <ion-input label-placement="stacked" formControlName="lastname"
          label="{{ 'REGISTER.LASTNAME_LABEL' | translate }}" class="ion-text-left"></ion-input>
      </ion-item>

      <ion-item>
        <ion-input label-placement="stacked" formControlName="email" label="{{ 'REGISTER.EMAIL_LABEL' | translate }}"
          class="ion-text-left"></ion-input>
      </ion-item>

      <ion-item>
        <ion-input label-placement="stacked" formControlName="phone" label="{{ 'REGISTER.PHONE_LABEL' | translate }}"
          class="ion-text-left"></ion-input>
      </ion-item>

      <ion-item class="country-item">
        <ion-select formControlName="country" interface="popover" label-placement="stacked"
          label="{{ 'REGISTER.COUNTRY_LABEL' | translate }}">
          <ion-select-option *ngFor="let country of countries" [value]="country.id">
            {{ country.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item class="country-item">
        <ion-select formControlName="language" interface="popover" label-placement="stacked"
          label="{{ 'REGISTER.LANGUAGE_LABEL' | translate }}">
          <ion-select-option *ngFor="let language of languages" [value]="language.id">
            {{ language.name }}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-button expand="block" (click)="save()" [disabled]="!form.valid">{{ 'REGISTER.CREATE_FREE_ACCOUNT_BUTTON' |
        translate }}</ion-button>
    </form>
  </div>
</ion-content>