"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.Report = void 0;
var models_1 = require("../models");
var report_template_1 = require("./report-template");
var Report = /** @class */ (function (_super) {
    __extends(Report, _super);
    function Report(template) {
        var _this = _super.call(this) || this; // Call super constructor
        if (template) {
            Object.assign(_this, template); // Copy template properties
            // Generate unique IDs for inspection targets
            _this.reporting_targets.forEach(function (target) {
                target.id = (0, models_1.generateUniqueId)();
            });
        }
        _this.id = (0, models_1.generateUniqueId)(); // Generate unique ID for the report
        _this.project_id = "";
        _this.asset_id = ""; // Added asset_id
        _this.receivers = [];
        _this.date = "";
        _this.pdf_url = "";
        _this.created_at = new Date().toISOString();
        _this.created_by = ""; // Set created_by when creating the report
        _this.modified_at = new Date().toISOString();
        _this.modified_by = ""; // Set modified_by when creating the report
        _this.inspection_elements = [];
        return _this;
    }
    return Report;
}(report_template_1.ReportTemplate));
exports.Report = Report;
