<ion-header>
  <ion-toolbar>
    <ion-buttons slot="start">
      <ion-icon (click)="dismiss(true)" name="close"></ion-icon>
    </ion-buttons>
    <ion-title>{{ 'RECEIPTS.RECEIPT_DETAILS_TITLE' | translate }}</ion-title>
  </ion-toolbar>
</ion-header>

<ion-content>
  <div style="padding: 10px;">
    <form class=" form" [formGroup]="form">

      <ion-item>
        <ion-label>{{ 'RECEIPTS.DATE' | translate }}</ion-label>
        <ion-datetime-button datetime="date_picker"></ion-datetime-button>

        <ion-modal [keepContentsMounted]="true">
          <ng-template>
            <ion-datetime id="date_picker" formControlName="date" name="date" presentation="date"></ion-datetime>
          </ng-template>
        </ion-modal>
      </ion-item>

      <ion-item *ngIf="projects && projects.length > 0">
        <ion-select formControlName="project" interface="popover" label="{{ 'RECEIPTS.PROJECT' | translate }}">
          <ion-select-option value=""></ion-select-option>
          <ion-select-option *ngFor=" let project of projects" [value]="project.id">{{project.name}}
          </ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-select formControlName="account" interface="popover" label="{{ 'RECEIPTS.CATEGORY' | translate }}">
          <ion-select-option *ngFor="let aC of accountingClassifiers"
            value="{{aC.number}}">{{aC.name}}</ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-select formControlName="vat_class" interface="popover" label="{{ 'RECEIPTS.VAT_CLASS' | translate }}">
          <ion-select-option *ngFor="let vC of vatClasses" value="{{vC.id}}">{{vC.name}}</ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-textarea label="{{ 'RECEIPTS.DESCRIPTION' | translate }}" formControlName="description"
          rows="3"></ion-textarea>
      </ion-item>

      <ion-item>
        <ion-input type="text" formControlName="issuer_name" name="issuer_name"
          label="{{ 'RECEIPTS.ISSUER_NAME' | translate }}"></ion-input>
      </ion-item>

      <ion-item>
        <ion-input type="number" lang="fi" formControlName="amount" name="amount"
          label="{{ 'RECEIPTS.AMOUNT' | translate }}"></ion-input>
      </ion-item>

      <ion-item>
        <ion-select formControlName="currency" interface="popover" label="{{ 'RECEIPTS.CURRENCY' | translate }}">
          <ion-select-option *ngFor="let currency of currencyList"
            value="{{currency.id}}">{{currency.name}}</ion-select-option>
        </ion-select>
      </ion-item>

      <ion-item>
        <ion-button (click)="fileInput.click()">
          <ion-icon name="attach-outline"></ion-icon>{{ 'RECEIPTS.ATTACH_RECEIPT' | translate }}
        </ion-button>
        <input #fileInput type="file" id="file" type="file" name="file" accept=".png,.jpg,.jpeg"
          (change)="selectFile($event)">
      </ion-item>

      <ion-item>
        <ion-list *ngIf="receipt && receipt.files && receipt.files.length > 0">
          <ion-item *ngFor="let file of receipt.files; let i = index">
            <img src="{{file}}" height="100">
            <ion-icon name="trash" slot="end" (click)="deletePhoto(i)"></ion-icon>
          </ion-item>
        </ion-list>
        <label *ngIf="!receipt || !receipt.files || receipt.files.length === 0">{{ 'RECEIPTS.NO_UPLOADED_RECEIPT' |
          translate }}</label>
      </ion-item>
      <ion-item>
        <ion-button (click)="save()" [disabled]="!form.valid">{{ 'GENERAL.SAVE' | translate }}</ion-button>
      </ion-item>
    </form>
  </div>

</ion-content>