"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapWorkDayToMetricsBase = mapWorkDayToMetricsBase;
function mapWorkDayToMetricsBase(workday) {
    return {
        data_distance: workday.data_distance,
        data_duration: workday.data_duration,
        idling_seconds: workday.idling_seconds,
        eco_speed_seconds: workday.eco_speed_seconds,
        coasting_seconds: workday.coasting_seconds,
        cruise_seconds: workday.cruise_seconds,
        optimal_rpm_seconds: workday.optimal_rpm_seconds,
        harsh_acceleration: workday.harsh_events.filter(function (event) { return event.event_name === 'harsh_acceleration'; }).length,
        harsh_braking: workday.harsh_events.filter(function (event) { return event.event_name === 'harsh_braking'; }).length,
        harsh_cornering: workday.harsh_events.filter(function (event) { return event.event_name === 'harsh_cornering'; }).length,
    };
}
