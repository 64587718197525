import {
  Auth,
  createUserWithEmailAndPassword,
  signInWithEmailAndPassword,
  signInWithPopup,
  signOut,
  sendPasswordResetEmail,
  getIdTokenResult,
  getAuth,
  deleteUser,
} from '@angular/fire/auth';

import { Inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ROLE_CONTEXT } from '../data/role-context';
import { SubscriberService } from './subscriber.service';
import { UserProfile } from '@shared/models/user-profile';
import { LoginData } from '@shared/models/login-data';
import { UserService } from './user.service';
import { TranslateService } from '@ngx-translate/core';
import { User } from '@shared/models';


@Injectable()
export class AuthService {
  private user: any;
  private subject = new BehaviorSubject<any>({});

  constructor(
    private auth: Auth,
    private subscriberService: SubscriberService,
    private userService: UserService,
    private translate: TranslateService,
    @Inject(ROLE_CONTEXT) private RoleConfig: []
  ) {

    this.auth.onAuthStateChanged(async (user) => {
      this.user = user;
      if (user) {
        const token = await user.getIdTokenResult();
        this.setProfile(user, token);
      } else {
        //localStorage.setItem('user', 'null');
      }
    });

    this.auth.onIdTokenChanged(async (user) => {
      this.user = user;
      if (user) {
        const token = await user.getIdTokenResult();
        this.setProfile(user, token);
      } else {
        //localStorage.setItem('user', 'null');
      }
    });
  }

  async setProfile(user, token) {
    //@ts-ignore
    if (token.claims !== undefined && (token.claims['subscriber_id'] !== undefined || token.claims['subscriber_id_array'] !== undefined)) {

      let subscriber = null;
      let userObject: User = null;

      if (token.claims['subscriber_id_array'] !== undefined && token.claims['subscriber_id_array'].length > 0) {
        console.log(`We are using multi account setup, user has access to ${JSON.stringify(token.claims['subscriber_id_array'])}`);
        subscriber = await this.subscriberService.get(token.claims['subscriber_id_array'][token.claims['subscriber_id_array'].length - 1]);
        userObject = await this.userService.get(token.claims['subscriber_id_array'][token.claims['subscriber_id_array'].length - 1], user.uid);
      }
      else if (token.claims['subscriber_id'] !== undefined) {
        console.log("We are using single account setup");
        subscriber = await this.subscriberService.get(token.claims['subscriber_id'] as string);
        userObject = await this.userService.get(token.claims['subscriber_id'], user.uid);
      }
      else {
        await signOut(this.auth);
        throw ('SUBSCRIBER_MISSING');
      }

      //@ts-ignore
      if (!this.RoleConfig.includes(userObject.role)) {
        console.log(`Role '${userObject.role}' does not match to list ${JSON.stringify(this.RoleConfig)} `);
        await signOut(this.auth);
        throw ('REQUIRED_ROLE_MISSING');
      }
      console.log(`Using subscriber: ${JSON.stringify(subscriber)}`);

      const userProfile: UserProfile = {
        name: `${userObject.firstname} ${userObject.lastname}` || "",
        email: userObject.email,
        subscriber_type: subscriber.subscriber_type || "free",
        subscriber_name: subscriber.name || "",
        timezone: subscriber.timezone || "Europe/Helsinki",
        language: userObject.language !== undefined && userObject.language.length > 0 ? userObject.language : "fi-FI",
        subscriber_id: subscriber.id as string || "",
        role: userObject.role as string || "",
        user_id: userObject.id as string,
        features: userObject.features as [],
        subscriber_access: token.claims['subscriber_id_array'] || []
      };

      this.subject.next(userProfile);
      let userString = JSON.stringify(user);

      localStorage.setItem('user', userString);
      localStorage.setItem('userProfile', JSON.stringify(userProfile));

      const langParts = userProfile.language.split('-');
      this.translate.use(langParts[0]);
    }

  }

  async login({ email, password }: LoginData) {
    console.log("Logging in");
    try {

      const data = await signInWithEmailAndPassword(this.auth, email, password);
      const token = await getIdTokenResult(data.user);

      //@ts-ignore
      if (!this.RoleConfig.includes(token.claims['role'])) {
        throw new Error('REQUIRED_ROLE_MISSING');
      }

      // await this.setProfile(data.user, token);
    } catch (err) {
      // Re-throw the error so it can be caught in loginUser

      if (err.message === 'REQUIRED_ROLE_MISSING') {
        throw err;
      }
      throw new Error('LOGIN_ERROR_MESSAGE');
    }
  }

  register({ email, password }: LoginData) {
    return createUserWithEmailAndPassword(this.auth, email, password);
  }

  logout() {

    localStorage.removeItem('user');
    localStorage.removeItem('userProfile');
    return signOut(this.auth);
  }

  getUserProfile(): any {
    return this.subject;
  }

  getUser() {
    return JSON.parse(localStorage.getItem('user')!);
  }

  getUserProfileStatic(): UserProfile {
    return JSON.parse(localStorage.getItem('userProfile')!);
  }

  resetPassword(email: string) {
    return sendPasswordResetEmail(this.auth, email);
  }

}