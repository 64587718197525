import { Injectable } from '@angular/core';
import { Firestore, collection, addDoc, doc, deleteDoc, updateDoc, getDocs, query, orderBy, collectionGroup, where } from '@angular/fire/firestore';
import { Clocking } from '@shared/models/clocking';

@Injectable()
export class ClockingService {

    constructor(private firestore: Firestore) {
    }

    async add(subscriberId: string, workdayId: string, clocking: Clocking): Promise<string> {
        clocking.created_at = new Date().toISOString();
        clocking.modified_at = new Date().toISOString();
        return await addDoc(collection(this.firestore, `subscribers/${subscriberId}/work-days/${workdayId}/clockings`), JSON.parse(JSON.stringify(clocking))).then(d => {
            return d.id;
        });
    }

    update(subscriberId: string, clockingId: string, clocking: Clocking): any {
        clocking.modified_at = new Date().toISOString();
        updateDoc(doc(this.firestore, `subscribers/${subscriberId}/work-days/${clocking.workday_id}/clockings/${clockingId}`), JSON.parse(JSON.stringify(clocking)));
    }

    delete(subscriberId: string, workdayId: string, clockingId: string): any {
        deleteDoc(doc(this.firestore, `subscribers/${subscriberId}/work-days/${workdayId}/clockings/${clockingId}`));
    }

    async getAllByState(subscriberId: string, workdayId: string, state: string): Promise<Clocking[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/work-days/${workdayId}/clockings`), where('state', '==', state), orderBy('start_time', 'desc'),);

        const data = await getDocs(q);

        const replySet: Clocking[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new Clocking(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    async getAll(subscriberId: string, workdayId: string,): Promise<Clocking[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/work-days/${workdayId}/clockings`), orderBy('start_time', 'asc'),);

        const data = await getDocs(q);

        const replySet: Clocking[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new Clocking(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }


    async getUserAllOverProjects(subscriberId: string, userId: string, state: string): Promise<Clocking[]> {

        const q = query(
            collectionGroup(this.firestore, 'clockings'),
            where('subscriber_id', '==', subscriberId),
            where('user_id', '==', userId),
            where('state', '==', state),
            orderBy('start_time', 'desc')
        );


        const data = await getDocs(q);

        const replySet: Clocking[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new Clocking(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    async getAllOfProject(subscriberId: string, projectId: string, state: string): Promise<Clocking[]> {

        const q = query(
            collectionGroup(this.firestore, 'clockings'),
            where('subscriber_id', '==', subscriberId),
            where('project_id', '==', projectId),
            where('state', '==', state),
            orderBy('start_time', 'desc')
        );

        const data = await getDocs(q);

        const replySet: Clocking[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new Clocking(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    async getAllOverProject(subscriberId: string, startTime: string): Promise<Clocking[]> {
        console.log(`Trying to find clockings after: ${startTime}`);
        const q = query(
            collectionGroup(this.firestore, 'clockings'),
            where('subscriber_id', '==', subscriberId),
            where('end_time', '>', startTime),
            orderBy('end_time', 'desc')
        );

        const data = await getDocs(q);

        const replySet: Clocking[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new Clocking(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

}