import { Injectable } from '@angular/core';
import {
    Firestore,
    collectionData,
    collection,
    addDoc,
    doc,
    deleteDoc,
    updateDoc,
    getDocs,
    query,
    orderBy,
    where,
    getDoc,
    DocumentSnapshot,
} from '@angular/fire/firestore';
import { UserProject, Project } from '@shared/models';
import { Observable } from 'rxjs';

@Injectable()
export class ProjectService {
    constructor(private firestore: Firestore) { }

    async add(subscriberId: string, project: Project): Promise<string> {
        return await addDoc(
            collection(this.firestore, `subscribers/${subscriberId}/projects`),
            Object.assign({}, project)
        ).then((d) => {
            return d.id;
        });
    }

    update(subscriberId: string, projectId: string, project: Project): any {
        updateDoc(doc(this.firestore, `subscribers/${subscriberId}/projects/${projectId}`), {
            ...project,
        });
    }

    delete(subscriberId: string, projectId: string): any {
        deleteDoc(doc(this.firestore, `subscribers/${subscriberId}/projects/${projectId}`));
    }

    getAllLive(subscriberId: string) {
        return collectionData(
            collection(this.firestore, `subscribers/${subscriberId}/projects`),
            { idField: 'id' }
        ) as Observable<Project[]>;
    }

    async getAll(subscriberId: string): Promise<Project[]> {
        const q = query(
            collection(this.firestore, `subscribers/${subscriberId}/projects`),
            orderBy('name', 'asc')
        );

        const data = await getDocs(q);

        const replySet: Project[] = [];

        data.docs.forEach((doc) => {
            const o = Object.assign(new Project(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    async getAllByUserAcl(subscriberId: string, userId: string): Promise<Project[]> {
        const q = query(
            collection(this.firestore, `subscribers/${subscriberId}/projects`),
            where('user_id_list', 'array-contains', userId),
            orderBy('name', 'asc')
        );

        const data = await getDocs(q);

        const replySet: Project[] = [];

        data.docs.forEach((doc) => {
            const o = Object.assign(new Project(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    // This is a synced list of user projects. We need to remember that the user can see projects via direct membership or team membership. So this list must be always synced
    async getUserProjects(subscriberId: string, userId: string): Promise<UserProject[]> {
        const q = query(
            collection(this.firestore, `subscribers/${subscriberId}/users/${userId}/user-projects`),
            orderBy('project_name', 'desc')
        );

        const data = await getDocs(q);

        const replySet: UserProject[] = [];

        data.docs.forEach((doc) => {
            const o = Object.assign(new UserProject(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    async get(subscriberId: string, projectId: string): Promise<Project> {
        const projectDoc = doc(this.firestore, `subscribers/${subscriberId}/projects/${projectId}`);
        const projectSnapshot: DocumentSnapshot = await getDoc(projectDoc);

        if (projectSnapshot.exists()) {
            const projectData = projectSnapshot.data();
            const project = Object.assign(new Project(), projectData);
            project.id = projectId;
            return project;
        } else {
            throw new Error('Project not found');
        }
    }
}