import { Component, Inject, OnInit, EventEmitter, Input, Output } from "@angular/core";
import {
  UntypedFormBuilder,
  Validators,
} from "@angular/forms";
import { ToastController } from "@ionic/angular";
import { Router } from "@angular/router";
import { AuthService } from "../../services/auth.service";
import { APP_NAME, ALLOW_REGISTRATION } from "../../tokens/app.tokens";
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: "app-login",
  templateUrl: "./login.page.html",
  styleUrls: ["./login.page.scss"],
})
export class LoginPage implements OnInit {

  public year: string;
  form: any;
  @Output() formData: EventEmitter<{
    email: string;
    password: string;
  }> = new EventEmitter();

  constructor(
    @Inject(APP_NAME) public appName: string,
    @Inject(ALLOW_REGISTRATION) public allowRegistration: boolean,
    private router: Router,
    private readonly authService: AuthService,
    private toastController: ToastController,
    private translate: TranslateService,
    private fb: UntypedFormBuilder,
  ) {
    const now = new Date();
    this.year = String(now.getFullYear());
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      email: ['', [Validators.required, Validators.email]],
      password: ['', Validators.required],
    });
  }

  get email() {
    return this.form.get('email');
  }

  get password() {
    return this.form.get('password');
  }

  requestPassword() {
    this.router.navigateByUrl("password-reset");
  }

  createAccount() {
    console.log("createAccount called");
    this.router.navigateByUrl("registration").then(success => {
      console.log("Navigation successful:", success);
    }).catch(err => {
      console.error("Navigation error:", err);
    });
  }

  onSubmit() {
    this.formData.emit(this.form.value);
    this.loginUser(this.form.value);
  }

  async presentToast(text) {
    const toast = await this.toastController.create({
      message: text,
      duration: 2000,
    });
    toast.present();
  }

  async loginUser(value) {
    try {
      await this.authService.login(value);
      this.router.navigate(['/main']);
    } catch (err) {
      this.presentToast(this.translate.instant(`LOGIN.${err.message}`));
    }
  }
}