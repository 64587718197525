import { Injectable } from '@angular/core';
import { Firestore, collectionData, collection, addDoc, doc, deleteDoc, updateDoc, getDocs, orderBy, query, getDoc, where } from '@angular/fire/firestore';
import { Asset } from '@shared/models/asset';
import { Observable } from 'rxjs';

@Injectable()
export class AssetService {

    constructor(private firestore: Firestore) {
    }

    async add(subscriberId: string, asset: Asset): Promise<string> {
        return await addDoc(collection(this.firestore, `subscribers/${subscriberId}/assets`), Object.assign({}, asset)).then(d => {
            return d.id;
        });
    }

    update(subscriberId: string, assetId: string, asset: Asset): any {
        updateDoc(doc(this.firestore, `subscribers/${subscriberId}/assets/${assetId}`), { ...asset });
    }

    delete(subscriberId: string, assetId: string): any {
        deleteDoc(doc(this.firestore, `subscribers/${subscriberId}/assets/${assetId}`));
    }

    subscribeAll(subscriberId: string): any {
        return collectionData(collection(this.firestore, `subscribers/${subscriberId}/assets`), { idField: 'id' }) as Observable<Asset[]>;
    }

    async get(subscriberId: string, assetId: string): Promise<Asset> {
        const ref = doc(this.firestore, `subscribers/${subscriberId}/assets/${assetId}`);
        const asset = Object.assign(new Asset(), (await getDoc(ref)).data());
        asset.id = assetId;
        return asset;
    }

    async getByStickerId(subscriberId: string, stickerId: string): Promise<Asset | null> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/assets`), where('sticker_id', '==', stickerId));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.size > 0) {
            const doc = querySnapshot.docs[0];
            const asset = Object.assign(new Asset(), doc.data());
            asset.id = doc.id;
            return asset;
        } else {
            return null; // Asset not found
        }
    }

    async getByNfcId(subscriberId: string, nfcId: string): Promise<Asset | null> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/assets`), where('nfc_id', '==', nfcId));
        const querySnapshot = await getDocs(q);

        if (querySnapshot.size > 0) {
            const doc = querySnapshot.docs[0];
            const asset = Object.assign(new Asset(), doc.data());
            asset.id = doc.id;
            return asset;
        } else {
            return null; // Asset not found
        }
    }

    async getAll(subscriberId: string): Promise<Asset[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/assets`), orderBy('name', 'asc'),);

        const data = await getDocs(q);

        const replySet: Asset[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new Asset(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    async getAllSortSeen(subscriberId: string): Promise<Asset[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/assets`), orderBy('last_seen', 'asc'),);

        const data = await getDocs(q);

        const replySet: Asset[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new Asset(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }

    async getAllByType(subscriberId: string, type: string): Promise<Asset[]> {
        const q = query(collection(this.firestore, `subscribers/${subscriberId}/assets`), where('asset_type', '==', type), orderBy('name', 'asc'),);

        const data = await getDocs(q);

        const replySet: Asset[] = [];

        data.docs.forEach(doc => {
            const o = Object.assign(new Asset(), doc.data());
            o.id = doc.id;
            replySet.push(o);
        });

        return replySet;
    }
}