import { formatISO } from 'date-fns'

export function convertToISOUTC(dateString: string): string {
    // Replace space with 'T' and remove fractional seconds
    const cleanedDateString = dateString.replace(' ', 'T').split('.')[0] + 'Z';

    // Create a Date object from the cleaned string
    const date = new Date(cleanedDateString);

    // Format the date to ISO 8601 with UTC
    return formatISO(date, { representation: 'complete', format: 'extended' });
}